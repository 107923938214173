.reviewListContainer {
    background: #F9FAFB;
    padding: 40px;
    margin-top: 80px;
}

.reviewListHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reviewHeaderLeft {
    display: flex;
    gap: 16px;
    align-items: center;
}

.reviewCountContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #878F99;
}

.reviewTotalCount {
    color: black;
}

.reviewSortSelect {
    border: none;
    background-color: #F9FAFB;
    height: 20px;
}

.moreReviewButtonContainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.moreReviewButton{
    border: none;
    background: #F9FAFB;
    cursor: pointer;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #878F99;
}
.moreReviewButton > svg{
    width: 20px;
    height: 20px;
    fill: #878F99;
}

