.lectureRequestForm{
    background: #F9FAFB;
    border-radius: 12px;
    padding: 40px;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.lectureRequestDetailContainer{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
}

.lectureRequestDetailTitle{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #30353D;
}

.lectureRequestDetailText{
    border: 1px solid #DADDE0;
    border-radius: 12px;
    background: #FFFFFF;
    width: 100%;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    resize: none;
    height: 133px;
}

.lectureRequestDetail{
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.discountRateContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

}

.discountRateHeader{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #30353D;
}

.discountRate{
    display: flex;
    height:100%;
    align-items: center;
    justify-content: center;
    color: #DB2344;
}

.lectureRequestButtonContainer{
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-top: 40px;
}

.lectureRequestPriceGrid{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 4fr;
    gap: 40px;
}