.lectureListContainer {
}

.lectureSearchInputContainer {
    height: 20px;
    display: flex;
    border: 1px solid #DADDE0;
    border-radius: 12px;
    width: 306px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
}

.lectureSearchInput {
    width: 270px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: black;
    border: none;
}

.lectureSearchInput::placeholder {
    color: #A9B0B8;
}

.lectureSearchInputContainer > .searchSvg {
    cursor: pointer;
}

.lectureTotalContainer {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #878F99;
}

.lectureTotal {
    color: #30353D;
}

.lectureSearchContainer {
    display: flex;
    gap: 8px;
    align-items: center;
}

.lectureListPlatformDropDown {
    width: 210px;
    border: 1px solid #DADDE0;
    border-radius: 12px;
    padding: 12px 16px;
    color: #A9B0B8;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("../../images/chevron-down.svg") no-repeat right 16px center;
    background-size: 20px;
    height: 46px;
}

.regis
rNewLecture {
    background: #0A0A0D;
    border-radius: 12px;
    color: white;
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.lectureListTopContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lectureListTableHeader {
    margin-top: 18px;
    display: grid;
    grid-template-columns: 130fr 110fr 140fr 540fr 140fr 120fr 120fr;
    background: #F9FAFB;
    border-bottom: 1px solid #EBEDF0;
    justify-items: start;
}

.lectureListTableColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
    gap:20px;
}

.lectureTableSort{
    display: flex;
    flex-direction: column;
}

.lectureTableSort > div > svg{
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: #878F99;
}

.lectureTableSort > div {
    width: 16px;
    height: 16px;
}

.lectureTableSort > div.active > svg{
    fill: black;
}

.registerNewLecture{
    background: #0A0A0D;
    border-radius: 12px;
    color: white;
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}