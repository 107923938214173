.lectureTableRow{
    display: grid;
    grid-template-columns: 130fr 110fr 140fr 540fr 140fr 120fr 120fr;
    border-bottom: 1px solid #EBEDF0;
    justify-items: start;
}

.lectureTableRow:hover{
    background: #F9FAFB;
}

.lectureTableRowColumn{
    padding: 24px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #67707A;
    text-align: start;
    word-break: break-word;
}

.lectureTableRowColumn > .toggle{
    width: 42px;
    height: 24px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0px;
    cursor: pointer;
}