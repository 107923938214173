.header {
    text-align: start;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}

.tableHeader {
    display: grid;
    grid-template-columns: 150fr 300fr 180fr;
    background: #F9FAFB;
    border-bottom: 1px solid #EBEDF0;
    border-radius: 12px 12px 0px 0px;
}

.headerColumn {
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    align-items: center;
}

.sortColumn {

}


.sortChevron {
    display: flex;
    flex-direction: column;
}

.sortChevron > span {
    width: 16px;
    height: 16px;
}

.sortChevron > span > svg {
    fill: #878F99;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.tableRow {
    display: grid;
    grid-template-columns: 150fr 300fr 180fr;
    justify-items: start;
    border-bottom: 1px solid #EBEDF0;
    border-radius: 0px;
}

.rowColumn {
    padding: 24px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #67707A;
}

.sortSelect {
    background: none;
    border: none;
    color: #67707A;
}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    display: flex;
    gap: 8px;
    margin-top: 16px;

}

.numberContainer {
    display: flex;
    justify-items: center;
    align-items: center;
    gap: 8px;
}

.page {
    width: 24px;
    height: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #0A0A0D;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0px;
}

.page:hover {
    color: #DB2344;
    background: rgba(219, 35, 68, 0.04);
    border-radius: 30px;
}

.page.active {
    color: #DB2344;
}

span.active > svg {
    fill: black;
}


.paginationChevron {
    width: 24px;
    height: 24px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0px;
}

.paginationChevron.disable > svg {
    fill: #EBEDF0;
}

.keywordColumn {
    display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
}