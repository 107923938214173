.formInputTextContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;
}

.formInputTextName{
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #30353D;

}

.formInputContainer{
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;

}

.formInputText{
    border: 1px solid #DADDE0;
    border-radius: 12px;
    background: #FFFFFF;
    height: 20px;
    width: 100%;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}