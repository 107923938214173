.container{
    margin-top: 40px;
}
.topContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sort{
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    cursor: pointer;
}

.searchContainer{
    display: flex;
    gap: 16px;
    align-items: center;

}

.inputContainer{
    display: flex;
    gap: 3px;
    padding: 12px 16px;
    border: 1px solid #DADDE0;
    border-radius: 12px;
}

.inputSearch{
    width: 251px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    outline: none;
}

.searchSvg{
    width: 20px;
    height: 20px;
}

.searchSvg > svg{
    fill: #67707A;
}

.totalCountContainer{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #878F99;
}

.totalCount{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #30353D;
}

.table {
    width: 100%;
    margin-top: 20px;
}

.tableHeaderRow{
    background: #F9FAFB;
    border-bottom: 1px solid #EBEDF0;
    border-radius: 0px;
    display: grid;
    grid-template-columns: 180fr 120fr 180fr 140fr 140fr 240fr 140fr;
    align-items: center;
    padding: 8px 20px;
    gap: 40px;
}


.tableHeaderColumn{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    text-align: start;
}