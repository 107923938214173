.header {
    text-align: start;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
}
.container{
    margin-top: 80px;
}

.keywordContainer{
    display: flex;
    gap: 8px;
}



.addContainer{
    display: flex;
    gap:4px;
    border: none;
    padding: 0px;
    background: none;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #A9B0B8;
    cursor: pointer;

}
.contentContainer{
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 8px;

}

.plusSvg{
    fill:#A9B0B8;
}
