.container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.buttonContainer {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.exitButton {
    display: flex;
    width: 160px;
    height: 56px;
    padding: 18px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: #EBEDF0;
    border: none;
    cursor: pointer;
    color: #878F99;

    /* Button/medium-600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.registerButton {
    display: flex;
    width: 160px;
    height: 56px;
    padding: 18px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: none;
    color: #DADDE0;

    /* Button/medium-600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.registerButton.active {
    background: #DB2344;
    color: #F9FAFB;

    /* Button/medium-600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    cursor: pointer;
}