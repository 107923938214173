.dashboardContainer {
    display: flex;
    flex-direction: column;
}

.dashboardHeader {
    display: flex;
    justify-content: space-between;
}


.dashboardFlex {
    display: flex;
    background: #F9FAFB;
    border-radius: 12px;
    padding: 24px 40px;
    justify-content: space-between;
}