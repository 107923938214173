body {
    font-family: 'Pretendard-Regular';
    position: relative;
    padding: 5vh 160px 0px;
    background-color: #F9FAFB;
}

@media screen and (max-width: 1728px){
    body {
        font-family: 'Pretendard-Regular';
        position: relative;
        padding: 5vh 0px 0px;
        background-color: #F9FAFB;
    }
}

.indexContainer {

}

.mainContainer {
    margin-left: 220px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}