.lectureRequestCard{
    width: 457px;
    height: 93px;
    background: #F9FAFB;
    border-radius: 12px;
    padding: 0px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lectureRequestInfo{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
}

.lectureRequestTime {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A9B0B8;
}

.lectureRequestText{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #30353D;
}

.chevronIcon {
    width: 24px;
    height: 24px;
}