.rejectLectureRequestModal {
    display: flex;
    flex-direction: column;
}

.rejectLectureRequestHeader {
    background: #F9FAFB;
    padding: 40px;
}

.rejectLectureRequestHeader > h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #0A0A0D;
    margin: 0px 0px 8px;
}

.rejectLectureRequestHeader > h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    margin: 0px;
}

.rejectLectureRequestHeader > div {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.rejectReason {
    display: flex;
    gap: 22px;

}

.rejectReasonContainer {
    display: flex;
    flex-direction: column;
    padding: 36px 40px;
    gap: 32px;
    cursor: pointer;
}

.rejectReasonCheckSvg {
    fill: #DADDE0;
    width: 20px;
    height: 20px;
}

.rejectReasonText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #30353D;
}

.rejectReason.active > .rejectReasonText {
    color: #DB2344;
}

.rejectReason.active > .rejectReasonCheckSvg {
    fill: #DB2344;
}

.rejectReasonInput {
    display: block;
    border: 1px solid #DADDE0;
    border-radius: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: black;
    padding: 12px 16px;
}

.rejectReasonInput::placeholder {
    color: #A9B0B8;
}

.rejectReasonDirect {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.rejectLectureModalButtonContainer {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    margin: 0px 40px 40px;
    height: 50px;
    gap: 8px;
}

.rejectLectureModalButtonContainer > .exitButton {
    background: #EBEDF0;
    border-radius: 12px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #878F99;
    cursor: pointer;
}

.rejectLectureModalButtonContainer > .rejectButton {
    cursor: pointer;
    background: #F5F6F7;
    border-radius: 12px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #DADDE0;
}

.rejectButton.active {
    background: #DB2344;
    color: white;
}