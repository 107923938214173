.container{
    border-radius: 12px;

}

.top {
    padding: 30px 30px;
    background: #F9FAFB;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #0A0A0D;
    border-radius: 12px;
}

.table {
    padding: 0px 30px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.moreBtnContainer{
    background-color: white;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #878F99;
}

.moreBtn{
    display: flex;
    gap: 8px;
    cursor: pointer;

}

.moreBtn > svg{
    width: 20px;
    height: 20px;
    fill: #878F99;
}

.xClose{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 35px;
    right: 35px;
    cursor: pointer;
}