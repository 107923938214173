.sideBar {
    height:100%;
}

.sideBarContainer {
    height:80vh;
    display: flex;
    flex-direction: column;
    background: #67707A;
    width: 200px;
    align-items: center;
    padding-top:40px;
    padding-bottom: 20px;
    border-radius: 12px;
    margin-bottom: 40px;
    position: fixed;
}

.mainLogo {
    width: 112px;
    height: 22px;
    margin-bottom: 60px;
}

.line {
    width: 100%;
    border-bottom: 1px solid #878F99;
    margin: 12px 0px;
}

.bottomMenuName {
    align-self: start;
    color: white;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
}

.logOutContainer {
    position: absolute;
    bottom: 10px;
}