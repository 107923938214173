.row {
    border-bottom: 1px solid #EBEDF0;
    display: grid;
    grid-template-columns: 90fr 70fr 100fr 660fr 180fr;
    align-items: center;
    padding: 18px 20px;
    gap: 40px;
}

.row:hover {
    background: #F9FAFB;
}

.column {
    color: #67707A;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-align: left;
}