.close{
    position: absolute;
    top:34px;
    right: 34px;
    cursor: pointer;
    width:24px;
    height:24px;
}
.close > svg{
    width:24px;
    height:24px;
}


.header{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #0A0A0D;

}

.body{
    margin-top: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;

}

.yesButton{
    margin-top: 50px;
    width: 100%;
    border: none;
    background: #DB2344;
    border-radius: 12px;
    color: white;
    padding: 16px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
}