.header {
    background: #F9FAFB;
    padding: 35px;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #0A0A0D;
    border-radius: 12px;
}

.table {
    width: 100%;
}

.close {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 35px;
    right: 35px;
    cursor: pointer;
}

.headerRow {
    display: grid;
    grid-template-columns: 220fr 400fr;
    padding: 18px 20px;
    gap: 40px;
    background: #F9FAFB;
    border-bottom: 1px solid #EBEDF0;
    border-radius: 12px 12px 0px 0px;
}

.headerColumn {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    text-align: start;
}

.dateColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sortColumn {
    display: flex;
    flex-direction: column;
}

.sort {
    width: 12px;
    height: 12px;
    cursor: pointer;
}

.sort > svg {
    fill: #878F99;
    width: 14px;
    height: 14px;
}

.bodyRow {
    display: grid;
    grid-template-columns: 220fr 400fr;
    padding: 18px 20px;
    gap: 40px;
    border-bottom: 1px solid #EBEDF0;
}

.bodyColumn {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #67707A;

}

.tableContainer {
    margin: 40px;
}

.moreBtnContainer {
    display: flex;
    width: 100%;
    justify-content: center;
}

.moreBtn {
    background: none;
    border: none;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #878F99;
    margin-bottom: 55px;
    cursor: pointer;
}

.moreBtnSvg {
    width: 20px;
    height: 20px;
}

.moreBtnSvg > svg {
    fill: #878F99;
}

.active > svg {
    fill: black;
}