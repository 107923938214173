.editorContainer {
    border-radius: 12px;
    border: 1px solid #DADDE0;
    background: white;
}

.toolbar {
    border-bottom: 1px solid #DADDE0 !important;
    border-radius: 12px 12px 0px 0px !important;

}

.textContainer {
    padding: 0px 16px;
}

.container {
    display: flex;
    flex-direction: column;
    gap: 40px
}

.inputContainer {
    width: 880px;
    display: flex;
    gap: 40px;
}

.calenderContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: start;
}

.header {
    color: #30353D;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 142.857% */
}

.editor {
    display: flex;
    gap: 16px;
    flex-direction: column;
    align-items: start;
}

.imageContainer {
    width: 409px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #DADDE0;
    border-radius: 12px;
    padding: 12px 16px;
    align-items: center;
    background: white;
}

.mainBannerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    align-items: start;
}

.buttonContainer {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.exitButton {
    display: flex;
    width: 160px;
    height: 56px;
    padding: 18px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    background: #EBEDF0;
    border: none;
    cursor: pointer;
    color: #878F99;

    /* Button/medium-600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.registerButton {
    display: flex;
    width: 160px;
    height: 56px;
    padding: 18px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    border: none;
    color: #DADDE0;

    /* Button/medium-600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
}

.registerButton.active {
    background: #DB2344;
    color: #F9FAFB;

    /* Button/medium-600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 125% */
    cursor: pointer;
}