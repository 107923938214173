.lectureRequestTableBody{

}

.lectureRequestTableRow{
    border-bottom: 1px solid #EBEDF0;
    display: grid;
    grid-template-columns: 130px 140px 1.5fr 1fr;
}

.lectureRequestTableColumn{
    padding: 24px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #67707A;
    text-align: start;
}

.lectureRequestTableButtonContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-around;
    align-items: center;
    margin: 0px 30px;
    gap: 8px;
}

.lectureRequestTableButton{
    display: flex;
    background: #FFFFFF;
    height: 40px;
    cursor: pointer;
    border: 1px solid #DADDE0;
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #878F99;
    text-decoration: none;
    align-items: center;
    justify-content: center;
}

.lectureRequestGoButtonContainer{
    height: 100%;
    display: flex;
    gap: 8px;
    justify-content: center;
    align-items: center;
}

.lectureRequestTableRow:hover{
    background: #F9FAFB;
}
