.registerLectureRequestButton{
    width: 160px;
    height: 56px;
    background: #F5F6F7;
    border-radius: 12px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #DADDE0;
}

.registerLectureRequestButton.active{
    background: #DB2344;
    color:white;
    cursor: pointer;
}