.calenderContainer{
    display: flex;
    align-items: center;
    gap: 8px;
}

.periodContainer {
    display: flex;
    align-items: center;
    border: 1px solid #DADDE0;
    border-radius: 12px;
    padding: 12px 16px;
    background: white;
}

.calenderIcon {
    width: 24px;
    height: 24px;
}

.time .react-datepicker__input-container > input{
    width: 140px;
}