.menuButton {
    display: flex;
    gap: 24px;
    width:160px;
    padding: 10px 10px;
    margin: 0px 10px;
    cursor: pointer;
}

.menuButtonIcon {
    width: 20px;
    height: 20px;
    fill: black;
    color:black;
}

.menuName {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #A9B0B8;
}

.menuContent{
    display: flex;
    gap: 8px;
    align-items: center
}

.menuBadge{
    padding: 2px 8px;
    border-radius: 40px;
    background: #DB2344;
    color: white;
}