.rowContainer {
    display: grid;
    grid-template-columns: 130fr 140fr  140fr 440fr 130fr 120fr 220fr 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #67707A;
    border-bottom: 1px solid #EBEDF0;
    border-radius: 12px 0px 0px 0px;
    align-items: center;
}

.rowContainer:hover {
    background: #F9FAFB;
}

.column {
    padding: 18px 20px;
    text-align: start;
    word-break: break-word;
}

.gradeContainer {
    display: flex;
    gap: 8px;
}

.star {
    width: 20px;
    height: 20px;
}

.lectureTitle {

    width: 356px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A9B0B8;
}

.reviewContent {
    width: 356px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #67707A;

}

.more {
    width: 24px;
    height: 24px;
    align-self: end;
    cursor: pointer;
}

.more > svg {
    width: 24px;
    height: 24px;
    fill: #67707A;
}

.contentContainer {
    display: flex;
    gap: 20px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.reviewMoreNotActive {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /*height: 48px;*/
}

.lectureMorNotActive {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.reportActive {
    color: #DB2344;
}

.deleteBtn {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #878F99;
    background: #FFFFFF;
    border-radius: 12px;
    cursor: pointer;
    border: 1px solid #DADDE0;
    height: 40px;
    padding: 12px 40px;
    justify-self: center;
}

.buttonContainer {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: end;
    margin-right: 20px;
    height: 100%;
}

.pinButton {
    cursor: pointer;
    grid-column: 8;
    margin-right: 20px;
}

.tooltipContainer {
    display: block;
}

.tooltip {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.tooltipText {
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 12px;
    background: #30353D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    color: #FFF;
    margin-bottom: -4px;
}

.postCourseTarget {
    color: #67707A;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}