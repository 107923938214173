.categoryModal {
    display: flex;
    flex-direction: column;
}

.categoryModalXCloseSvg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 28px;
    right: 28px;
    cursor: pointer;
}

.categoryModalHeader {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #0A0A0D;
    margin-bottom: 40px;
}

.categoryModalNameContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    width: 100%;
}

.categoryModalName{
    background: #FFFFFF;
    padding: 12px 18px;
    border: 1px solid #DADDE0;
    border-radius: 12px;
}

.categoryModalButtonContainer{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 8px;
}

.categoryModalExitButton{
    background: #EBEDF0;
    border-radius: 12px;
    border: none;
    padding: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #878F99;
    cursor: pointer;
}

.categoryModalAppendButton{
    background: #F5F6F7;
    border-radius: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #DADDE0;
    padding: 16px;
    border: none;
    cursor: pointer;
}

.categoryModalAppendButton.active{
    background: #DB2344;
    color: white;
}
