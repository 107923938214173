.categoryTreeContainer{
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 24px;
    color: #67707A;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}