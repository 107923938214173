.container{
    display: flex;
    align-items: center;
}

.chevronLeft{
    width: 24px;
    height: 24px;
}

.chevronLeft:hover{
    cursor: pointer;
}

.modal{
    display: flex;
    flex-direction: column;
}

.header{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #0A0A0D;
    margin-bottom: 8px;
}

.body{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;

}

.buttonContainer{
    display: grid;
    grid-template-columns: 1fr 1.8fr;
    gap: 8px;
    margin-top: 50px;
}

.modalExitButton{
    border: none;
    background: #EBEDF0;
    border-radius: 12px;
    padding: 15px 56px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #878F99;
    cursor: pointer;
}
.modalDeleteButton{
    border: none;
    background: #DB2344;
    border-radius: 12px;
    padding: 15px 56px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: white;
    cursor: pointer;
}

.closeBtn {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 28px;
    right: 28px;
    cursor: pointer;
}
