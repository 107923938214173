.lectureRequestTopContainer{
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    align-items: center;
}

.lectureRequestSearchContainer{
    display: flex;
    align-items: center;
    gap: 16px;
}
.lectureRequestSearchInputContainer{
    height: 20px;
    display: flex;
    border: 1px solid #DADDE0;
    border-radius: 12px;
    width: 306px;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
}
.lectureRequestSearchInput{
    width: 270px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: black;
    border: none;
}

.lectureRequestSearchInput::placeholder{
    color: #A9B0B8;

}

.lectureRequestSearchInputContainer.searchSvg{
    width: 20px;
    height: 20px;
}

.registerNewLectureButton{
    background: #0A0A0D;
    border-radius: 12px;
    color: white;
    width: 160px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lectureRequestTotalContainer{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #878F99;
}

.lectureRequestTotal{
    color:#30353D;
}

.lectureRequestTableHeader{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 130px 140px 1fr;
    justify-items: start;
    background: #F9FAFB;
    border-bottom: 1px solid #EBEDF0;
    color: #67707A;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.tableHeaderColumn{
    padding: 18px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.lectureRequestTableDateSort{
    display: flex;
    flex-direction: column;
}
.lectureRequestTableDateSort > div{
    width: 16px;
    height: 16px;
}


.lectureRequestTableDateSort > div > svg {
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill: #878F99;

}

.lectureRequestTableDateSort > div.active > svg {
    fill: black;
}