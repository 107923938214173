.table {
    width: 100%;
    margin-top: 20px;
}

.tableHeaderRow {
    border-radius: 12px 12px 0px 0px;
    background: #F9FAFB;
    border-bottom: 1px solid #EBEDF0;
    display: grid;
    grid-template-columns: 90fr 70fr 100fr 660fr 180fr;
    align-items: center;
    padding: 18px 20px;
    gap: 40px;
}


.tableHeaderColumn {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    text-align: start;
}

.searchContainer {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.searchInputContainer {
    display: flex;
    gap: 16px;
    align-items: center;
}

.searchInput {
    width: 274px;
    display: flex;
    justify-content: space-between;
    gap: 8px;
    border-radius: 12px;
    border: 1px solid #DADDE0;
    padding: 12px 16px;
    background: #FFF;
}

.searchInput > input {
    border: none;
    width: 100%;
}

.searchInput > svg {
    width: 20px;
    height: 20px;
}

.countContainer {
    color: #878F99;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.count {
    color: #30353D;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.sort{
    height: 20px;
    color: #67707A;
    border: none;
    /* Body/medium-600 */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}