.reviewContainer{
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 200px;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.reviewContainer:hover{
    background: rgba(10, 10, 13, 0.04);
    border-radius: 12px;
}

.reviewGradeContainer {
    display: flex;
    gap:4px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #0A0A0D;
    align-items: center;


}
.reviewGradeContainer > svg {
    width: 14px;
    height: 14px;
}

.reviewHeader{
    display: flex;
    align-items: center;
    gap: 8px;
}

.reviewNickname {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #30353D;
}

.reviewDateContainer{
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #878F99;
}

.reviewRecommendationContainer{
    display: flex;
    background: #F9FAFB;
    border: 1px solid #EBEDF0;
    border-radius: 24px;
    padding: 6px 16px;
    gap: 4px;
    align-items: center;
    color: #67707A;
}

.reviewRecommendationContainer > svg{
    fill:#67707A ;
    width: 14px;
    height: 14px;
}

.reportContainer{
    display: flex;
    background: #F9FAFB;
    border: 1px solid #EBEDF0;
    border-radius: 24px;
    padding: 6px 16px;
    gap: 4px;
    align-items: center;
    color: #67707A;
}

.reportContainer > svg{
    fill:#67707A ;
    width: 14px;
    height: 14px;
}

.reportContainer.active > svg{
    fill:#DB2344 ;

}
.reportContainer.active{
    color:#DB2344 ;
}

.reviewBody{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #30353D;
    text-align: start;
}

.reviewLeftContainer{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.reviewDeleteButton{
    background: #FFFFFF;
    border-radius: 12px;
    padding: 12px 46px;
    height: 40px;
    border: none;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #878F99;
    cursor: pointer;
}

.userInfo{
    color: #878F99;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
}

.postCourseTarget{
    margin-top: 12px;
    margin-bottom: 4px;
    color: #30353D;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}

.pinnedBtn{
    width: 24px;
    height: 24px;
    padding: 0px;
    margin: 0px;
    border: none;
    background: none;
    cursor: pointer;
}

.btnContainer{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 16px;
}