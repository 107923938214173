.lectureRetouchButton{
    border-radius: 12px;
    background: #F5F6F7;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #DADDE0;
    border: none;
    padding: 18px 52px;
}

.lectureRetouchButton.active{
    background: #DB2344;
    cursor: pointer;
    color: white;
}