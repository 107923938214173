.keyword{
    background: #F9FAFB;
    border-radius: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #878F99;
    padding:8px 16px;
    display: flex;
    gap: 4px;
    align-items: center;
}

.keyword.editMode{
    background: #EBEDF0;
    color: #67707A;
}

.deleteBtn{
    border:none;
    padding:0px;
    background: none;
    width: 20px;
    height: 20px;
}

.deleteBtn > svg{
    width: 20px;
    height: 20px;
    fill:#878F99;
    cursor: pointer;
}