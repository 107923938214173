.linkContainer{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.linkContainer > div{
    cursor: pointer;
}

.link{
    display: flex;
    gap: 16px;
    color: #A9B0B8;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
}
.active{
    color: #0A0A0D;
}
.registerBtn{
    display: flex;
    width: 160px;
    padding: 15px 0px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 12px;
    background: #0A0A0D;
    cursor: pointer;
    color: white;
    text-decoration: none;
}