.row {
    border-bottom: 1px solid #EBEDF0;
    display: grid;
    grid-template-columns: 90fr 70fr 100fr 660fr 180fr;
    align-items: center;
    padding: 18px 20px;
    gap: 40px;
}

.row:hover {
    background: #F9FAFB;
}

.column {
    color: #67707A;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    text-align: left;
}

.questionContainer {

}

.question {
    color: #0A0A0D;

    /* Body/large-600 */
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
}

.answer {
    color: #67707A;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
}

.chevron {
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.chevron > svg{
    width: 24px;
    height: 24px;
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toggle{
    width: 42px;
    height: 24px;
    cursor: pointer;
}

.toggle > svg{
    width: 42px;
    height: 24px;
}
