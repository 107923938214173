.row{
    display: grid;
    grid-template-columns: 180fr 120fr 180fr 140fr 140fr 240fr 140fr;
    align-items: center;
    padding: 12px 20px;
    gap: 40px;
    border-bottom: 1px solid #EBEDF0;
}

.column{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #67707A;
    text-align: start;
}

.oauthSvg{
    width: 20px;
    height: 20px;
}

.oauthContainer{
    display: flex;
    gap: 8px;
}

.countContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
}

.count{
    display: flex;
    gap: 4px;
    background: #F9FAFB;
    border-radius: 12px;
    padding: 4px 10px;
}

.reportCount{
    display: flex;
    gap: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #DB2344;
    background: rgba(219, 35, 68, 0.04);
    border-radius: 12px;
    padding: 4px 10px;
}
.dateContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    cursor: pointer;

    align-items: flex-start;
}
.date{
    padding: 4px 10px;
    background: #F9FAFB;
    border-radius: 12px;
    text-align: start;
}
.marketingBtnContainer{
    display: flex;
    flex-direction: row;
    gap: 8px;
}
.marketingBtn{
    background: #F9FAFB;
    border-radius: 12px;
    padding: 4px 10px;
}

.marketingBtn.active{
    background: #DB2344;
    color: white;
}