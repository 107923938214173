.logo{
}

.container{
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnContainer{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 60px;
}

.btn{
    display: flex;
    justify-content: space-between;
    width: 342px;
    padding: 13px 16px;
    align-items: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-decoration: none;
    color: black;
    border-radius: 12px;
}

.kakao{
background-color: #FFE523;
}

.google{
    border: 1px solid #DADDE0;
    background: #FFFFFF;
}
.apple{
    background: #000000;
    color: white;
}

.loginLogo{
    width: 24px;
    height: 24px;
}