.LectureRequestAlarmContainer{
    width: 506px;
}

.lectureRequestAlarmHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lectureRequestMore{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A9B0B8;
    text-decoration: none;
}

.lectureRequestCardContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
}