.selectBox{
    cursor: pointer;
    background: #EBEDF0;
    border-radius: 12px;
    padding: 16px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #878F99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectBoxXCircleSvg{
    width: 20px;
    height: 20px;
    fill: #878F99;
    display: flex;
    border: none;
    padding: 0px;
    cursor: pointer;
}

.selectBoxEditMode{
    justify-content: space-between;

}

.selectBox.active{
    background: #0A0A0D;
    color: #F9FAFB;
}