.container {
    padding: 24px 40px;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.headerContainer {
    display: flex;
    justify-content: space-between;

}

.header {
    color: #30353D;

    /* Title/medium-700 */
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
}

.viewedContainer {
    display: flex;
    gap: 4px;
    color: #30353D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    align-items: center;
    cursor: pointer;
}

.viewedContainer > svg {
    width: 20px;
    height: 20px;
    fill: #DADDE0;
}

.notificationContainer{
    display: flex;
    gap: 8px;
    flex-direction: column;
}

.notification {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 10px;
    border-radius: 12px;
    background: #F9FAFB;
    cursor: pointer;
}

.dateContainer{
    display: flex;
    gap: 4px;

    color:#A9B0B8;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.noNotification{
    display: flex;
    height: 440px;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 12px;
    background:#F9FAFB;
    color: #878F99;
    text-align: center;

    /* Body/medium-600 */
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}