.header{
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 40px;
    color: #0A0A0D;
}

.inputContainer{
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.inputHeader{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
}

.inputBox{
    border: 1px solid #DADDE0;
    border-radius: 12px;
    padding: 12px 16px;
}

.buttonContainer{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 140fr 252fr;
    gap: 8px;
}

.exitButton {
    background: #EBEDF0;
    border-radius: 12px;
    padding: 16px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: none;
    cursor: pointer;
    color: #878F99;
}

.addButton{
    background: #F5F6F7;
    border-radius: 12px;
    padding: 16px 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: none;
    cursor: pointer;
    color: #DADDE0;
}

.addButton.active{
    background: #DB2344;
    color: white;
}

.closeButton{
    width: 24px;
    height: 24px;
    cursor: pointer;
    position:absolute;
    top:34px;
    right: 34px;
}
