.container {

}

.topContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 63px;
}

.searchContainer {
    display: flex;
    align-items: center;
    gap: 8px;
}

.searchInput {
    width: 306px;
    padding: 12px 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: black;
    border: 1px solid #DADDE0;
    border-radius: 12px;
}

.searchInput::placeholder {
    color: #A9B0B8;
}

.totalCountContainer {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #878F99;
}

.totalCount {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #30353D;
}

.sort {
    border: none;
    color: #67707A;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
}

.tableHeader {
    display: grid;
    grid-template-columns: 130fr 140fr  140fr 440fr 130fr 120fr 220fr 44px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #67707A;
    border-bottom: 1px solid #EBEDF0;
    border-radius: 12px 0px 0px 0px;
    background: #F9FAFB;
    margin-top: 20px;
    align-items: center;
}

.tableHeaderColumn {
    padding: 18px 20px;
    text-align: start;
}

.dateColumn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 20px;
}

.sort {
    display: flex;
    flex-direction: column;
}

.sort > div > svg {
    width: 16px;
    height: 16px;
    fill: #878F99;
}

.sort > div {
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.sort > div.active > svg {
    fill: black;
}