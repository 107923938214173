.selectBoxContainer{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 8px;
    width: 100%;
}

.selectContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.selectHeader{
    display: flex;
    gap: 16px;
}

.selectEditor {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A9B0B8;
    gap: 6px;
    align-items: center;
    cursor: pointer;
}

.selectEditorSvg{
    fill: #A9B0B8;
    width: 16px;
    height: 16px;
}

.selectName {
    ont-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #30353D;
}

.plusButtonContainer {
    display: flex;
    gap: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #A9B0B8;
    align-items: center;
    cursor: pointer;
}

.selectPlusButtonSvg {
    fill: #A9B0B8;
    /*border: 1px dashed #DADDE0;*/
    border-radius: 100px;
    width: 24px;
    height: 24px;

}

.selectCheckButton {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #DB2344;
    gap: 6px;
    cursor: pointer;

}

.selectCheckSvg{
    height: 16px;
    width: 16px;
    fill: #DB2344;
}