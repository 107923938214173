.dashboardTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboardNameContainer{
    display: flex;
    flex-direction: column;
    align-items: start;
}

.dashboardName {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #30353D;
    margin: 0px;
}

.dashboardCountContainer {
    display: flex;
    background: #67707A;
    border-radius: 60px;
    padding: 8px 12px;
    align-items: center;
}

.currentCount{
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
}

.totalCount{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #DADDE0;

}

.dashboardGraph {
    width: 100%;
    background-color: white;
    border-radius: 12px;

}

.dashboardCardContainer{
    display: flex;
    flex-direction: column;
    width: 22%;
    gap: 16px;
}

.averageReview {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #878F99;
}