.container {
    display: flex;
    gap: 24px;
    width: 160px;
    padding: 10px 10px;
    margin: 0px 10px;
    cursor: pointer;
    color: #A9B0B8;
}

.container > svg {
    fill: #A9B0B8
}

.content {
    display: flex;
    gap: 8px;
    align-items: center
}

.badge {
    padding: 2px 8px;
    border-radius: 40px;
    background: #DB2344;
    color: white;
}